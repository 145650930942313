import React, { useEffect, useState } from 'react'
import './App.css'
import { PublicDisplay } from './components/organisms/PublicDisplay/PublicDisplay'
import { getMonday } from './logic/util'
import { reducer, StateProvider } from './logic/state'
import { auth } from './logic/firebase'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { ControlPanel } from './components/organisms/ControlPanel/ControlPanel'
import { ClickableList } from './components/molecules/ClickableList/ClickableList'
import { EmailList } from './components/organisms/EmailList/EmailList'
import { Sensorpush } from './components/organisms/Sensorpush/Sensorpush'
import { Login } from './components/organisms/Login/Login'
import { CoreTaskList } from './components/organisms/CoreTaskList/CoreTaskList'
import { PrintProductionPlan } from './components/organisms/PrintProductionPlan/PrintProductionPlan'
import { getGlobalSettings } from './services/firestore/global-settings.service'
import { WeekOverview } from './components/organisms/PublicDisplay/WeekOverview/WeekOverview'
import { signInWithEmailAndPassword } from 'firebase/auth'

const ONE_MINUTE_MS = 60000

const initialState = {
  monday: getMonday(new Date()),
}

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()

  // Check if the user is authenticated. If not, redirect to the login page.
  useEffect(() => {
    const checkForUser = async () => {
      try {
        if (auth.currentUser === null) {
          const query = new URLSearchParams(window.location.search)
          let password = query.get('password')
          if (password !== null) {
            await signInWithEmailAndPassword(
              auth,
              'firebaseauth-backend@kinderkueche.com',
              password
            )
          } else {
            navigate('/login')
          }
        }
      } catch (error) {
        console.log(error)
        navigate('/login')
      }
    }
    checkForUser()
  }, [navigate])

  // If authenticated, render the provided element.
  return children
}

const App: React.FC = () => {
  const [refreshIntervalCoreTasks, setRefreshIntervalCoreTasks] =
    useState(ONE_MINUTE_MS)

  useEffect(() => {
    const retrieveRefreshIntervalCoreTasks = async () => {
      if (auth.currentUser !== null) {
        const { refreshIntervalCoreTasks } = await getGlobalSettings()
        setRefreshIntervalCoreTasks(refreshIntervalCoreTasks)
      }
    }
    retrieveRefreshIntervalCoreTasks()
  }, [])

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/login"
            element={
              <div className="login">
                <Login />
              </div>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <ClickableList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/control-panel"
            element={
              <div className="App">
                <div className="controlPanel">
                  <ProtectedRoute>
                    <ControlPanel />
                  </ProtectedRoute>
                </div>
              </div>
            }
          />
          <Route
            path="/public-display"
            element={
              <div className="publicDisplay">
                <ProtectedRoute>
                  <PublicDisplay />
                </ProtectedRoute>
              </div>
            }
          />
          <Route
            path="/week-overview"
            element={
              <div className="weekOverview">
                <ProtectedRoute>
                  <WeekOverview />
                </ProtectedRoute>
              </div>
            }
          />
          <Route
            path="/email-list"
            element={
              <div className="emailList">
                <ProtectedRoute>
                  <EmailList />
                </ProtectedRoute>
                s
              </div>
            }
          />
          <Route
            path="/sensorpush"
            element={
              <div className="sensorpush">
                <ProtectedRoute>
                  <Sensorpush />
                </ProtectedRoute>
              </div>
            }
          />
          <Route
            path="/coreTasks"
            element={
              <div className="coreTasks">
                <ProtectedRoute>
                  <CoreTaskList
                    refreshIntervalCoreTasks={refreshIntervalCoreTasks}
                  />
                </ProtectedRoute>
              </div>
            }
          />
          <Route
            path="/printProductionPlan/:day"
            element={
              <div className="printProductionPlan">
                <ProtectedRoute>
                  <PrintProductionPlan />
                </ProtectedRoute>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </StateProvider>
  )
}

export default App
